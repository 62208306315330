<template>
  <!-- 采购合同管理 -->
  <div class="procurementContract">
    <!-- 搜索筛选 -->
    <FormSearch :reset="true" :getdata="getdata" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
        :operation-button="operationButton"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import { agentPurchaseContractFundsPage } from '@/http/agentApi/procurementContract'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      itemData: [
        { label: '采购合同编号', prop: 'contractNo', width: 160 },
        { label: '上游合同编号', prop: 'supplierContractNo', width: 160 },
        { label: '合作企业', prop: 'supplierCmpName', width: 200 },
        { label: '经销商', prop: 'custCmpName', width: 200 },
        { label: '货款(元)', prop: 'totalPayment', width: 80 },
        // { label: '交货方式', prop: 'deliveryType', width: 120, child: [{ dictName: '卖家配送', dictId: 1 }, { dictName: '买家自提', dictId: 2 }, { dictName: '直发', dictId: 3 }] },
        { label: '交收仓库 ', prop: 'deliveryWarehouseName', width: 120 },
        { label: '关联销售合同', prop: 'custContractNo', width: 140 },
        { label: '修改时间', prop: 'modifyTime', width: 140 },
        { label: '状态', prop: 'contractStatus', width: 80, child: this.$store.getters.getDictionaryItem('fundsContractStatus') }
      ],
      formItemArr: [
        { type: 'input', label: '上游合同', value: 'supplierContractNo' },
        { type: 'input', label: '合作企业', value: 'supplierCmpName' },
        { type: 'input', label: '经销商', value: 'custCmpName' },
        { type: 'input', label: '交收仓库', value: 'deliveryWarehouseName' },
        { type: 'input', label: '销售合同', value: 'custContractNo' }
      ],
      loading: false,
      total: 0,
      listData: [],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        custCmpName: '',
        supplierCmpName: ''
      }
    }
  },
  methods: {
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentPurchaseContractFundsPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 查看详情
    viewInfoTable({ id }) {
      this.$router.push({ path: '/procurementContract/procurementContractDetails', query: { id }})
    }
  }
}
</script>

<style lang="scss" scoped>
.procurementContract {
  .tableBox {
    margin-left: 92px;
    text-align: center;
    width: 1200px;
    border-collapse: collapse;

    td {
      width: 150px;
      height: 40px;
      border: 1px solid #ededed;
    }
  }

  .childrenTable {
    margin-left: 100px;
  }

  .buttonCombination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
